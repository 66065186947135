import * as React from "react";
import "./accordion-jager.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  typography: {
    fontFamily: ["Russo One"],
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ color: "#F6EB16", fontSize: "1,5rem" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#09342D" : "#09342D",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  background: "#151515",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : true);
  };

  return (
    <div style={{ textAlign: "center", background: "#000000" }}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          style={{ textAlign: "center" }}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography>
            <h2>SOFT DRINKS</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h1>22 LEI</h1>
              <h3>COCA-COLA 330ml </h3>
              <h3>FANTA 330ml </h3>
              <h3>SPRITE 330ml </h3>
              <h3>SCHWEPPES TONIC 330ml </h3>
              <h3>DORNA STILL WATER 500ml </h3>
              <h3>DORNA SPARKLING WATER 500ml </h3>
              <h1>80 LEI</h1>
              <h3>NATURAL JUICE 1l </h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            <h2>READY TO DRINK</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h1>30 LEI</h1>
              <h5>250ml</h5>
              <h3>SMIRNOFF ICE</h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            <h2>BEER</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h5>355ml</h5>
              {/* <h3>
                BECKS <span>20 LEI</span>
              </h3>*/}
              <h3>
                STELLA ARTOIS <span style={{ color: "#f1f100"  }}>27 LEI</span>
              </h3>
              <h3>
                CORONA EXTRA <span style={{ color: "#f1f100"  }}>32 LEI</span>
              </h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>
            <h2>ENERGY DRINK</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h1>27 LEI</h1>
              <h5>250ml</h5>
              <h3>RED BULL</h3>
              <h5>REGULAR - SUGAR FREE - TROPICAL - WATERMELON</h5>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>
            <h2>RUM</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h3>
                ZACAPA 23 700ml{" "}
                <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>875 LEI</span>
              </h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>
            <h2>VODKA</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h3>
                BELVEDERE SW EDITION 1.75l <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>2500 LEI</span>
              </h3>
              <h3>
                GREYGOOSE 700ml<span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>875 LEI</span>
              </h3>
              <h3>
                CIROC 700ml<span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>875 LEI</span>
              </h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography>
            <h2>GIN</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h3>
                TANQUERAY 10 700ml{" "}
                <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>875 LEI</span>
              </h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography>
            <h2>SCOTCH</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h3>
                JW DOUBLE BLACK 700 ml <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>875 LEI</span>
              </h3>
              <h3>
                JW BLUE LABEL 700ml <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>3000 LEI</span>
              </h3>
              <h3>
                LAGAVULIN 16 YO 700ml <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>1750 LEI</span>
              </h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel19"}
        onChange={handleChange("panel19")}
      >
        <AccordionSummary aria-controls="panel19d-content" id="panel19d-header">
          <Typography>
            <h2>TEQUILA</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h3>
                DON JULIO ANEJO 700ml <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>980 LEI</span>
              </h3>
              <h3>
                DON JULIO 1942 700ml <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>5000 LEI</span>
              </h3>
              <h3>
                CLASE AZUL PLATA 700ml <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>3000 LEI</span>
              </h3>
              <h3>
                CLASE AZUL REPOSADO 700ml <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>5000 LEI</span>
              </h3>
              <h3>
                CLASE AZUL REPOSADO 1,75l <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>12000 LEI</span>
              </h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
          <Typography>
            <h2>CHAMPAGNE</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
            <h5>750ml</h5>
            <h3>
                LAURENT PERRIER BRUT <span style={{ fontSize: "13px" }}>12% </span>
                <span style={{ color: "#f1f100"  }}>800 LEI</span>
              </h3>
              <h3>
                LAURENT PERRIER ROSE <span style={{ fontSize: "13px" }}>12% </span>
                <span style={{ color: "#f1f100"  }}>1000 LEI</span>
              </h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
      >
        <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
          <Typography>
            <h2>LONG ENERGY DRINKS</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h1>48 LEI</h1>
              <h5>250ml</h5>
              <h3>TANQUERAY RANGPUR & RED BULL </h3>
              <h3>KETEL ONE & RED BULL</h3>
              <h3>JW BLACK LABEL & REDBULL</h3>
              {/* <h3>GREYGOOSE & REDBULL</h3> */}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel11"}
        onChange={handleChange("panel11")}
      >
        <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
          <Typography>
            <h2>LONG DRINKS</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h1>45 LEI</h1>
              <h5>250ml</h5>
              <h3>TANQUERAY RANGPUR & TONIC</h3>
              <h3>PAMPERO ANIVERSARIO & COLA</h3>
              <h3>JW BLACK LABEL & SOFT DRINK</h3>
              <h3>KETEL ONE & SOFT DRINK</h3>
              {/* <h3>GREYGOOSE & SOFT DRINK</h3> */}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel12"}
        onChange={handleChange("panel12")}
      >
        <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
          <Typography>
            <h2>SHOTS</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h5>25ml</h5>
              <h3>
                DON JULIO ANEJO{" "}
                <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>35 LEI</span>
              </h3>
              <h3>
              DON JULIO REPOSADO <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>30 LEI</span>
              </h3>
              <h3>
                DON JULIO BLANCO <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>25 LEI</span>
              </h3>
              <h3>
                JAGERMEISTER <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>25 LEI</span>
              </h3>
              <h3>
                AMARETTO <span style={{ fontSize: "13px" }}>40% </span>
                <span style={{ color: "#f1f100"  }}>25 LEI</span>
              </h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion
        expanded={expanded === "panel13"}
        onChange={handleChange("panel13")}
      >
        <AccordionSummary aria-controls="panel13d-content" id="panel11d-header">
          <Typography>
            <h2>PROSECCO & SPUMANTE</h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="card">
              <h1>40 LEI</h1>
              <h5>100ml</h5>
              <h3>GRANDE VENTO <span style={{ fontSize: "13px" }}>11% </span></h3>
              <h3>ASTI MARTINI <span style={{ fontSize: "13px" }}>11% </span></h3>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}
